<!-- 这个原为是页面 改成组件引入了 -->
<template>
    <div style="text-align: left;">
        <div class="attr-menu-container">
            <el-select v-model="classifyChoose" @change="queryUserAttrTemplate" placeholder="请选择模板所在的产品分类">
                <el-option v-for="item in classifyData" :key="item.id" :label="item.classifyName" :value="item.id">
                </el-option>
            </el-select>
            <el-button @click="addAttrTemp" icon="el-icon-circle-plus" :disabled="!classifyChoose" type="primary">创建新的模板</el-button>
        </div>
        <div v-if="0" class="df df-ai-c df-jc-c text-center">
            <div class="m-t-50">
                <img src="@/assets/images/template/not-template.png">
                <div class="c-6 f16">您当前还未添加规格</div>
                <el-button type="primary" @click="addAttr" class="m-t-20">添加规格</el-button>
            </div>
        </div>
        <div style="width: 100%;min-height: 700px;">
            <template v-if="tempData">
                <template v-for="(item,index) in tempData.content">
                    <el-card shadow="never" style="margin-bottom: 25px;">
                        <div slot="header" class="my-header">
                            <div>{{item.templateName}}</div>
                            <div>
                                <el-button @click="editAttrTemp(item.id, item.templateName)" type="text">修改</el-button>
                                <el-button @click="delMessageBox('deleteAttrTemp',item.id)" type="text">删除</el-button>
                                <el-button @click="addAttr(item.id, item.templateName)" type="text">添加规格</el-button>
                            </div>
                        </div>
                        <div>
                            <div v-for="(v,i) of item.furnitureAttr" :key="i">
                                <el-table :data="v.furnitureAttrOption" :span-method="goodFormSpanMethod" border style="width: 100%; margin-top: 20px">
                                    <el-table-column label="规格名称" width="180">
                                        <template slot-scope="scope">
                                            {{scope.row.attrName}}
                                            <div>
                                                <el-button @click="editAttr(scope.row)" type="text">修改</el-button>
                                                <el-button @click="delMessageBox('deleteAttr',scope.row)" type="text">删除</el-button>
                                                <el-button @click="addAttrOption(scope.row)" type="text">添加规格值</el-button>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="optionValue" label="规格值">
                                        <template slot-scope="scope">
                                            <div v-if="scope.row.optionValue" class="optionValue">
                                                <div class="optionValueLeft">{{scope.row.optionValue}}</div>
                                                <div class="optionValueRight">
                                                    <el-button @click="editAttrOption(scope.row)" type="text">修改</el-button>
                                                    <el-button @click="delMessageBox('deleteAttrOption',scope.row)" type="text">删除</el-button>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </el-card>
                </template>
            </template>
        </div>
        <div>
            <el-divider></el-divider>
            <el-pagination v-if="tempData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="tempData.size" :total="tempData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import {furnitureClassify} from '@/service/index.js'
 import {localSet, localGet, localDel} from "@/store/store"


export default {
    name: "Attr",
    data() {
        return {
            classifyData: [],
            classifyChoose: null,
            pageSize: 10,
            pageNow: 0,
            tempData: [],
            enterpriseId:null,
            
        }
    },
    mounted() {
        const me = this;
        let enterprise=localGet('enterprise')
        this.enterpriseId=enterprise.id
        furnitureClassify.findByLtUserAuthAndClassifyCategory({enterpriseId:this.enterpriseId}).then(res => {
        // oucy.queryUserClassify(null).then(res => {
            console.log(res);
            me.classifyData = res;
            if(me.classifyData && me.classifyData.length){
                me.classifyChoose=me.classifyData[0].id
                me.queryUserAttrTemplate();
            }
        });

        // spec.addFurnitureAttrTemplate()
    },
    methods: {
        sizeChange: function(pageSize) {
            const me = this;
            me.pageSize = pageSize;
            me.queryUserAttrTemplate();
        },
        currentChange: function(current) {
            const me = this;
            me.pageNow = current - 1;
            me.queryUserAttrTemplate();
        },
        indexMethod: function(index) {
            const me = this;
            return me.pageSize * me.pageNow + index + 1;
        },
        queryUserAttrTemplate: function() {
            const me = this;
            return new Promise((resolve, reject) => {
                if (!!me.classifyChoose) {
                    oucy.postRequest('/client/product/furnitureattr/getAllAttrTemplate', {
                        furnitureClassifyId: me.classifyChoose,
                        enterpriseId:this.enterpriseId,
                        start: me.pageNow,
                        limit: me.pageSize
                    }).then(res => {
                        if (res.content) {
                            for (let v of res.content) {
                                if (v.furnitureAttr && v.furnitureAttr.length) {
                                    for (let vv of v.furnitureAttr) {
                                        // 处理规格与规值数据 为合并单元格和展示
                                        let index = 0
                                        if (vv.furnitureAttrOption && vv.furnitureAttrOption.length) {
                                            for (let vvv of vv.furnitureAttrOption) {
                                                vvv.tempId = v.id
                                                vvv.furnitureAttrId = vv.id
                                                vvv.attrName = vv.attrName
                                                vvv.len = vv.furnitureAttrOption.length
                                                vvv.index = index
                                                    ++index
                                            }
                                        } else {
                                            vv.furnitureAttrOption = [{
                                                furnitureAttrId: vv.id,
                                                attrName: vv.attrName,
                                                tempId: v.id,
                                            }]
                                        }
                                    }
                                } else {
                                    v.furnitureAttr = []
                                }
                            }
                        }
                        me.tempData = res;
                    });
                } else {
                    resolve();
                }

            })
        },
        addAttrTemp: function() {
            const me = this;
            me.$prompt('模板名称', '创建参数模版', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\S+$/,
                inputErrorMessage: '模板名称不正确'
            }).then(({ value }) => {
                oucy.postRequest('/client/product/furnitureattr/addFurnitureAttrTemplate', {
                    furnitureClassifyId: me.classifyChoose,
                    templateName: value,
                    enterpriseId:this.enterpriseId
                }).then(res => {
                    me.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                    me.queryUserAttrTemplate();
                });
            }).catch(err=>{
                console.log(err)
            });
        },
        editAttrTemp: function(tmpId, defaultName) {
            const me = this;
            me.$prompt('模板名称', '修改模版名称', {
                inputValue: defaultName,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\S+$/,
                inputErrorMessage: '模板名称不正确'
            }).then(({ value }) => {
                oucy.postRequest('/client/product/furnitureattr/updateFurnitureAttrTemplate', {
                    id: tmpId,
                    enterpriseId:this.enterpriseId,
                    templateName: value
                }).then(res => {
                    me.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    me.queryUserAttrTemplate();
                });
            });
        },
        deleteAttrTemp: function(tmpId) {
            const me = this;
            oucy.postRequest('/client/product/furnitureattr/deleteFurnitureAttrTemplate', {
                id: tmpId,
                enterpriseId:this.enterpriseId
            }).then(res => {
                me.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                me.queryUserAttrTemplate();
            });
        },
        addAttr: function(tempId, tempName) {
            const me = this;
            me.$prompt('规格名称', '新增规格名称', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\S+$/,
                inputErrorMessage: '规格名称不正确'
            }).then(({ value }) => {
                let has=false
                for(let v of me.tempData.content){
                    if(v.id==tempId){
                        console.log(v)
                        for(let vv of v.furnitureAttr){
                            if(vv.attrName==value){
                                has=true
                                break
                            }
                        }
                    }
                }
                if(has){
                    this.$message('规格名称"'+value+'"已存在')
                    return
                }
                oucy.postRequest('/client/product/furnitureattr/addFurnitureAttr', {
                    tempId: tempId,
                    attrName: value,
                    enterpriseId:this.enterpriseId
                }).then(res => {
                    me.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                    me.queryUserAttrTemplate();
                });
            });
        },
        editAttr: function(e) {
            const me = this;
            me.$prompt('规格名称', '修改规格名称', {
                inputValue: e.attrName,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\S+$/,
                inputErrorMessage: '规格名称不正确'
            }).then(({ value }) => {
                if(value==e.attrName){
                    console.log(66)
                    return
                }
                let has=false
                for(let v of me.tempData.content){
                    if(v.id==e.tempId){
                        console.log(v)
                        for(let vv of v.furnitureAttr){
                            if(vv.attrName==value){
                                has=true
                                break
                            }
                        }
                    }
                }
                if(has){
                    this.$message('规格名称"'+value+'"已存在')
                    return
                }
                oucy.postRequest('/client/product/furnitureattr/updateFurnitureAttr', {
                    id: e.furnitureAttrId,
                    tempId: e.tempId,
                    attrName: value,
                    enterpriseId:this.enterpriseId
                }).then(res => {
                    me.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    me.queryUserAttrTemplate();
                });
            });
        },
        deleteAttr: function(v) {
            const me = this;
            oucy.postRequest('/client/product/furnitureattr/delFurnitureAttr', {
                id: v.furnitureAttrId,
                enterpriseId:this.enterpriseId
            }).then(res => {
                me.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                me.queryUserAttrTemplate();
            });
        },
        goodFormSpanMethod: function({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if (row.len) {
                    if (row.index == 0) {
                        return {
                            rowspan: row.len,
                            colspan: 1
                        }
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0
                        }
                    }
                }
            }
        },
        addAttrOption: function(v) {
            console.log(v)
            const me = this;
            me.$prompt('请输入规格值', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\S+$/,
                inputErrorMessage: '规格值不正确'
            }).then(({ value }) => {
                oucy.postRequest('/client/product/furnitureattroption/addFurnitureAttrOption', {
                    furnitureAttrId: v.furnitureAttrId || v.id,
                    optionValue: value,
                    enterpriseId:this.enterpriseId
                }).then(res => {
                    me.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                    me.queryUserAttrTemplate();
                });

            });
        },
        editAttrOption: function(v) {
            console.log(v)
            const me = this;
            me.$prompt('请输入规格值', '提示', {
                inputValue: v.optionValue,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\S+$/,
                inputErrorMessage: '规格值不正确'
            }).then(({ value }) => {
                oucy.postRequest('/client/product/furnitureattroption/updateFurnitureAttrOption', {
                    id: v.id,
                    optionValue: value,
                    enterpriseId:this.enterpriseId
                }).then(res => {
                    me.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    me.queryUserAttrTemplate();
                });

            });
        },

        deleteAttrOption: function(v) {
            const me = this;
            oucy.postRequest('/client/product/furnitureattroption/delFurnitureAttrOption', {
                id: v.id,
                enterpriseId:this.enterpriseId
            }).then(res => {
                me.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                me.queryUserAttrTemplate();
            });
        },
        delMessageBox(fu, v, text) {
            this.$confirm(text || '此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this[fu](v)

            }).catch((err) => {
                console.log(err)
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },


    }
}
</script>
<style scoped>
.attr-menu-container {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}

.my-header {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
}

.top {
    background: #F2F4F6;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top .left {
    color: #666;
}

.top .right {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.optionValue {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>