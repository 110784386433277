<template>
    <div style="text-align: left">

        <el-tabs type="border-card" v-model="activePub" @tab-click="handlePath">
            <el-tab-pane label="产品发布" name="pub_product">
                <Good></Good>
                <!-- <PubEntrance v-if="activeIndex==='0'"/> -->
            </el-tab-pane>
            <el-tab-pane label="规格模板" name="pub_attr">
                <Attr></Attr>
                <!-- <PubEntrance v-if="activeIndex==='1'"/> -->
            </el-tab-pane>
            <el-tab-pane label="参数模版" name="pub_spec">
                <Spec></Spec>
                <!-- <PubEntrance v-if="activeIndex==='2'"/> -->
            </el-tab-pane>
        </el-tabs>

    </div>
</template>

<script>
    import Good from "./Publish/Good.vue";
    import Attr from "./Publish/Attr.vue";
    import Spec from "./Publish/Spec.vue";
    // import PubEntrance from "./PubEntrance";
    export default {
        name: "Publish",
        components:{
            // PubEntrance,
            Good,
            Attr,
            Spec,
        },
        data(){
            return{
                activePub:"pub_product",
                activeIndex:'0'
            }
        },
        mounted() {
            const me = this;
            let route_name = me.$route.name;
            console.log(route_name);
            if(!!route_name){
                if(route_name === 'Attr'){
                    me.activePub = 'pub_attr';
                    me.activeIndex = '1';
                } else if(route_name === 'Good'){
                    me.activePub = 'pub_product';
                    me.activeIndex = '0';
                } else if(route_name === 'Spec'){
                    me.activePub = 'pub_spec';
                    me.activeIndex = '2';
                }
            }
        },
        methods:{
            handlePath: function (tab, event) {
                return
                const me = this;
                const index = tab.index;
                me.activeIndex = index;
                if(index === '0'){
                    me.$router.push('/enter/publish/good')
                } else if(index === '1'){
                    me.$router.push('/enter/publish/attr')
                } else if(index === '2'){
                    me.$router.push('/enter/publish/spec')
                }

            }
        }
    }
</script>

<style scoped>

</style>
